var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "page-product-list"
  }, [_c('PageTop', {
    attrs: {
      "isLogin": _vm.isLogin
    }
  }), _c('HeadeSearch', {
    ref: "handleGetSum"
  }), _c('MainTabs', {
    attrs: {
      "isHover": _vm.isHover,
      "activeIndex": _vm.tabActive
    },
    on: {
      "getCurrentData": _vm.onClicktab
    }
  }), _c('div', {
    directives: [{
      name: "infinite-scroll",
      rawName: "v-infinite-scroll",
      value: _vm.loadMore,
      expression: "loadMore"
    }],
    attrs: {
      "infinite-scroll-disabled": "busy",
      "infinite-scroll-distance": "10"
    }
  }, [_c('div', {
    staticClass: "search-result-wrap"
  }, [_c('div', {
    staticClass: "result-order-top"
  }, [_c('ul', {
    staticClass: "result-order-wrap"
  }, [_c('li', {
    staticClass: "acti-zt"
  }, [_vm._v(" 活动精选")]), _vm._l(_vm.orderTypeList, function (item, index) {
    return _c('li', {
      key: index,
      on: {
        "click": function click($event) {
          return _vm.changeOrder(index);
        }
      }
    }, [_c('span', {
      class: {
        active: index === _vm.activeIdx
      }
    }, [_vm._v("切换" + _vm._s(item))])]);
  })], 2)]), !_vm.isListMode ? _c('ProductList', {
    attrs: {
      "productList": _vm.productList
    },
    on: {
      "handelImg": _vm.handelImg,
      "getGoodsList": _vm.getactivityList
    }
  }) : _c('div', [_c('ListShow', {
    attrs: {
      "productList": _vm.productList,
      "isExternal": _vm.isExternal
    },
    on: {
      "toAddCart": _vm.toAddCart
    }
  })], 1), _vm.productList.length ? _c('div', {
    staticClass: "bottom-more-style"
  }, [_vm._v(" " + _vm._s(_vm.bottomTitle) + " ")]) : _vm._e()], 1)]), _vm.totalNum === 0 ? [_vm._m(0)] : _vm._e(), _c('PageFooter')], 2);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "no-result-wrap"
  }, [_c('img', {
    attrs: {
      "src": require("./images/no-result.png"),
      "alt": ""
    }
  })]);
}];
export { render, staticRenderFns };