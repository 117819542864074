import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import { MainTabs } from "@components/main-tabs";
import { PageTop } from "@components/page-top";
import { HeadeSearch } from "@components/head-search";
import { orderTypeList } from "./list-mock-data.js";
import { ProductList } from "@components/product-list";
import { PageFooter } from "@components/page-footer";
import { ListShow } from "@components/list-show";
import { Account, AccountStorage } from "@services/account";
import { ActiveInfo } from "@components/active-info";
var account = new Account();
var accountStorage = new AccountStorage();
var classification = "/pjyy-deepexi-product-center/api/v1/product/PcProduct/homeclassification";
var getProductByCatalogId = "/pjyy-deepexi-product-center/api/v1/background/PcCatalog/getProductByCatalogId";
var activityList = '/pjyy-deepexi-aggregation/api/v1/advertising/findProductByAdvertising';
export default {
  data: function data() {
    return {
      busy: true,
      //上拉的参数
      isShowSearch: false,
      isHover: true,
      orderTypeList: orderTypeList,
      productList: [],
      //数据
      checkoutOptionsList: {
        one: [],
        tow: [],
        three: []
      },
      // 选中分类的筛选
      isShowMore: false,
      isLogin: false,
      tabActive: -1,
      activeIdx: 0,
      ASC: true,
      isListMode: false,
      saleSort: "",
      //销量排序
      priceSort: "2",
      //价格排序
      page: 1,
      // 当前页
      totalPageNumber: 1,
      //总页数
      bottomTitle: "正在加载.....",
      totalNum: 0,
      //搜索到的商品数量

      paramsId: this.$route.params.id,
      level: this.$route.params.level,
      productName: "",
      //搜索商品名称
      isClickActive: false,
      //是否勾选了
      isExternal: false,
      activiid: ''
    };
  },
  created: function created() {
    this.activiid = this.$route.query.activityid;
    this.isLogin = account.isLogined();
  },
  beforeRouteUpdate: function beforeRouteUpdate(to, from, next) {
    this.paramsId = to.params.id;
    this.level = to.params.level;
    next();
  },
  mounted: function mounted() {
    this.getactivityList();
    var userInfo = accountStorage.getCacheUserInfo();
    if (userInfo) {
      this.isExternal = userInfo.type == 1 ? true : false;
    }
  },
  methods: {
    getactivityList: function getactivityList() {
      var _this = this;
      var flag = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      var userInfo = accountStorage.getCacheUserInfo();
      console.log('sffff');
      if (!flag) {
        this.page = 1;
      }
      var data = {
        page: this.page,
        pageSize: 20,
        id: this.activiid
      };
      this.$getHttpClient().post(activityList, data).then(function (res) {
        _this.page++;
        if (res.data.data.list.list) {
          console.log(res.data.data.list.list);
          if (flag) {
            _this.productList = _this.productList.concat(res.data.data.list.list || []);
          } else {
            _this.productList = res.data.data.list.list || [];
          }
          _this.totalNum = res.data.data ? res.data.data.list.total : 0;
          _this.totalPageNumber = Math.ceil(_this.totalNum / 20);
          setTimeout(function () {
            _this.busy = false;
          }, 1000);
          console.log(_this.page);
          console.log(_this.totalPageNumber);
          if (_this.page > _this.totalPageNumber) {
            _this.bottomTitle = "数据已加载完";
            _this.busy = true;
          }
        }
      });
    },
    toAddCart: function toAddCart() {
      this.$refs.handleGetSum.getSum();
    },
    handelImg: function handelImg() {
      this.$refs.handleGetSum.getSum();
    },
    onSearchBlur: function onSearchBlur() {
      if (this.productName.length === 0) {
        this.isShowSearch = false;
      }
    },
    changeShow: function changeShow() {
      this.isShowSearch = !this.isShowSearch;
    },
    // 上拉加载更多
    loadMore: function loadMore() {
      console.log('sssssd');
      this.busy = true;
      console.log('this.page:' + this.page);
      console.log('this.totalPageNumber:' + this.totalPageNumber);
      if (this.page <= this.totalPageNumber) {
        this.bottomTitle = "正在加载...";
        this.getactivityList(true);
      }
    },
    // tab切换
    onClicktab: function onClicktab(event) {
      // window.sessionStorage.setItem("cindex",0);
      // if (window.sessionStorage.getItem("cindex")) {
      // } else {
      //   window.sessionStorage.setItem("cindex", 0);
      // }
      event.children.map(function (item) {
        item.pagenum = 0;
        item.datalist = [];
      });
      if (this.isExternal) {
        this.$buefy.toast.open({
          message: "您尚未成为正式会员，无法进入该页面",
          type: "is-danger"
        });
        this.$router.push({
          path: "/"
        });
      } else {
        this.$router.push({
          // path: "/channel/channel-detail/" + event.index + "/" +Number(window.sessionStorage.getItem("cindex"))
          path: "/channel/channel-detail/" + event.index + "/0"
        });
      }
    },
    // 点击综合排序、销量、价格排序
    changeOrder: function changeOrder(idx) {
      this.activeIdx = idx;
      if (idx === 0) {
        this.ASC = true;
        this.isListMode = !this.isListMode;
        if (this.isListMode) {
          this.orderTypeList[idx] = "大图模式";
        } else {
          this.orderTypeList[idx] = "列表模式";
        }
      }
    }
  },
  components: {
    PageTop: PageTop,
    HeadeSearch: HeadeSearch,
    MainTabs: MainTabs,
    ProductList: ProductList,
    PageFooter: PageFooter,
    ListShow: ListShow,
    ActiveInfo: ActiveInfo
  }
};